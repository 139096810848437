import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "heading" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrevPageButton = _resolveComponent("PrevPageButton")!
  const _component_CurrentUserAvatar = _resolveComponent("CurrentUserAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PrevPageButton),
    _createElementVNode("h1", {
      class: "ml-auto ml-lg-0 mb-0",
      innerHTML: _ctx.title
    }, null, 8, _hoisted_2),
    _createVNode(_component_CurrentUserAvatar, {
      "icon-only": true,
      class: "ml-auto"
    })
  ]))
}