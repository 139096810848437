
import {defineComponent, ref} from 'vue';
import CustomHeading from '../components/CustomHeading.vue';
import {z} from 'zod';

export default defineComponent({
    name: 'Handout',
    components: {CustomHeading},
    setup() {
        const title = ref('');
        const content = ref('');

        fetch('wp-json/wp/v2/pages?slug=handreiking-instructie&_fields=title,content&per_page=1').then((r) => r.json()).then((response) => {
            const result = z.array(z.object({
                title: z.object({rendered: z.string()}),
                content: z.object({rendered: z.string()}),
            })).parse(response);

            title.value = result[0].title.rendered;
            content.value = result[0].content.rendered;
        });

        return {title, content};
    }
})
