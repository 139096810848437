import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomHeading = _resolveComponent("CustomHeading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustomHeading, {
      title: _ctx.title,
      class: "custom-heading-steps-contact"
    }, null, 8, ["title"]),
    _createElementVNode("div", {
      class: "main__inner px-1 px-lg-5",
      innerHTML: _ctx.content
    }, null, 8, _hoisted_1)
  ], 64))
}